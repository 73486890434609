import React, { useEffect, useRef, useState } from 'react';
import { useFloating, flip, shift, autoUpdate, offset } from '@floating-ui/react-dom';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Callout, Link, TabSelect } from '@/shared/components';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { autoSlippageAvailable, isSudo } from '@/shared/featureFlags';
import { KnifeIcon, ShieldTickIcon, WarningTriangleIcon } from '@/shared/icons/small';
import { decimalRegex } from '@/shared/utils/regex';
import useSettingsStore from '../../../hooks/useSettingsStore';
import useSwapRequestStore, {
  selectAutoSlippageTolerancePercent,
} from '../../../hooks/useSwapRequestStore';
import ShieldIcon from '../SwapCard/icons/Shield';

const SLIPPAGE_MIN = 0.05;
const SLIPPAGE_MAX = 50;
const SLIPPAGE_WARNING_LOW = 0.5;
const SLIPPAGE_WARNING_HIGH = 2.5;
const SUDO_SLIPPAGE_MIN = -SLIPPAGE_MAX;

export const SlippageModeSection = ({ open }: { open?: boolean }) => {
  const { setSlippageTolerancePercent, slippageTolerancePercent } = useSettingsStore();
  const autoSlippagePercent = useSwapRequestStore(selectAutoSlippageTolerancePercent);
  const customInputRef = useRef<HTMLInputElement | null>(null);
  const [customInputString, setCustomInputString] = useState('');

  const onToggleMode = () => {
    if (slippageTolerancePercent === 'auto') {
      setCustomInputString(String(autoSlippagePercent));
      setSlippageTolerancePercent(autoSlippagePercent);
    } else {
      setSlippageTolerancePercent('auto');
    }
  };
  const onCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSudo || decimalRegex(2).test(e.target.value)) {
      setCustomInputString(e.target.value);
    }
  };
  const onCustomInputBlur = () => {
    const min = isSudo ? SUDO_SLIPPAGE_MIN : SLIPPAGE_MIN;
    let validated = Number(customInputString);
    if (!validated || validated < min) validated = min;
    if (validated > SLIPPAGE_MAX) validated = SLIPPAGE_MAX;

    setCustomInputString(String(validated));
    setSlippageTolerancePercent(validated);
  };

  useEffect(() => {
    if (open) setCustomInputString(String(slippageTolerancePercent));
  }, [open]);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row items-center justify-between font-aeonikMedium">
        {autoSlippageAvailable ? (
          <div className="flex flex-row gap-1 text-12 text-cf-light-2">
            <span>Mode</span>
            <QuestionMarkTooltip content="Auto mode automatically adjusts slippage tolerance to balance price risk and refund prevention" />
          </div>
        ) : (
          <div className="flex flex-row gap-1 text-12 text-cf-light-2">
            <span>Slippage tolerance</span>
            <QuestionMarkTooltip content="Maximum accepted difference between quote price and swap price" />
          </div>
        )}
        <div className="flex flex-row items-center gap-2">
          {autoSlippageAvailable && (
            <TabSelect
              tabs={[{ label: 'Auto' }, { label: 'Custom' }]}
              onSelectionChange={onToggleMode}
              selectedTab={{ label: slippageTolerancePercent === 'auto' ? 'Auto' : 'Custom' }}
              pillWidth={56}
              pillHeight={24}
              containerMargin={1}
              btnClasses="text-12"
            />
          )}
          <div
            className={classNames(
              'flex w-[56px] flex-row items-center gap-1 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 pr-2 text-12 transition',
              slippageTolerancePercent === 'auto'
                ? 'text-cf-light-1'
                : 'text-cf-white focus-within:border-cf-gray-5 focus-within:bg-cf-gray-4',
            )}
            onClick={() => {
              if (slippageTolerancePercent === 'auto') onToggleMode();
              setTimeout(() => customInputRef.current?.focus(), 0); // focus after rerender
            }}
          >
            <input
              data-testid="slippage-tolerance-input"
              inputMode="decimal"
              className="w-0 grow bg-transparent py-1 text-right outline-none disabled:pointer-events-none"
              value={slippageTolerancePercent === 'auto' ? autoSlippagePercent : customInputString}
              disabled={slippageTolerancePercent === 'auto'}
              onChange={onCustomInputChange}
              onBlur={onCustomInputBlur}
              ref={customInputRef}
            />
            <span
              className={classNames(
                'transition',
                slippageTolerancePercent === 'auto' ? 'text-cf-light-1' : 'text-cf-light-2',
              )}
            >
              %
            </span>
          </div>
          {isSudo && (
            <button
              data-testid="sudo-set-max-slippage"
              type="button"
              className="text-cf-red-2 hover:text-cf-red-1"
              onClick={() => {
                setCustomInputString(SUDO_SLIPPAGE_MIN.toString());
                setSlippageTolerancePercent(SUDO_SLIPPAGE_MIN);
              }}
            >
              <KnifeIcon />
            </button>
          )}
        </div>
      </div>
      {slippageTolerancePercent !== 'auto' &&
        Number(customInputString) > 0 &&
        Number(customInputString) < SLIPPAGE_WARNING_LOW && (
          <Callout type="warning" size="small">
            Increase the slippage tolerance to minimise the risk for refunds.
          </Callout>
        )}
      {slippageTolerancePercent !== 'auto' &&
        Number(customInputString) > 0 &&
        Number(customInputString) > SLIPPAGE_WARNING_HIGH && (
          <Callout type="warning" size="small">
            Lower the slippage tolerance to minimise the risk of unfavorable price movements.
          </Callout>
        )}
      {slippageTolerancePercent === 'auto' && autoSlippagePercent > SLIPPAGE_WARNING_HIGH && (
        <Callout type="warning" size="small">
          Suggested slippage tolerance is high. Consider lowering the amount or proceed with
          caution.
        </Callout>
      )}
    </div>
  );
};

export const SlippageProtectionSection = ({ open }: { open?: boolean }) => {
  const { setSwapDeadlineMinutes, swapDeadlineMinutes } = useSettingsStore();
  const swapDeadlineInputRef = useRef<HTMLInputElement | null>(null);
  const [swapDeadlineString, setSwapDeadlineString] = useState('');

  const onSwapDeadlineInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (decimalRegex(0).test(e.target.value)) {
      setSwapDeadlineString(e.target.value);
    }
  };
  const onSwapDeadlineInputBlur = () => {
    let validated = Number(swapDeadlineString);
    if (!validated || validated < 1) validated = 1;
    if (validated > 45) validated = 45;

    setSwapDeadlineString(String(validated));
    setSwapDeadlineMinutes(validated);
  };

  useEffect(() => {
    if (open) setSwapDeadlineString(String(swapDeadlineMinutes));
  }, [open]);

  return (
    <>
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center justify-between font-aeonikMedium text-14 text-cf-white">
          <div className="flex items-center gap-x-1">
            <ShieldIcon className="text-cf-blue-2" />
            Slippage Protection
          </div>
        </div>
        <span className="text-12 text-cf-light-2">
          Your deposit will be refunded if the price changes unfavourable above this percentage.{' '}
          <Link
            href="https://docs.chainflip.io/swapping/swapping-basics#minimum-accepted-price-slippage-protection"
            target="_blank"
            className="underline"
          >
            Learn more
          </Link>
        </span>
      </div>
      <div className="flex flex-col gap-y-2">
        <SlippageModeSection open={open} />
        <div className="flex flex-row items-center justify-between font-aeonikMedium">
          <div className="flex flex-row gap-1 text-12 text-cf-light-2">
            <span>Swap deadline</span>
            <QuestionMarkTooltip content="Time until deposit is refunded if price exceeds slippage tolerance" />
          </div>
          <div
            className={classNames(
              'flex w-[56px] flex-row items-center gap-1 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 pr-2 text-12 text-cf-white',
              'transition focus-within:border-cf-gray-5 focus-within:bg-cf-gray-4',
            )}
            onClick={() => swapDeadlineInputRef.current?.focus()}
          >
            <input
              data-testid="swap-deadline-input"
              inputMode="numeric"
              className="w-0 grow bg-transparent py-1 text-right outline-none"
              value={swapDeadlineString}
              onChange={onSwapDeadlineInputChange}
              onBlur={onSwapDeadlineInputBlur}
              ref={swapDeadlineInputRef}
            />
            <span className="text-cf-light-2">min</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const SlippageTolerancePopup = () => (
  <Popover>
    {({ open }) => {
      const { slippageTolerancePercent } = useSettingsStore();
      const autoSlippagePercent = useSwapRequestStore(selectAutoSlippageTolerancePercent);
      const effectiveSlippageTolerancePercent =
        slippageTolerancePercent === 'auto' ? autoSlippagePercent : slippageTolerancePercent;

      const { floatingStyles, refs } = useFloating({
        open,
        placement: 'bottom-end',
        middleware: [offset(8), flip(), shift({ padding: 16 })],
        whileElementsMounted: autoUpdate,
      });

      return (
        <>
          <Popover.Button
            ref={refs.setReference}
            className={classNames(
              'flex items-center gap-x-0.5 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 p-2 font-aeonikMedium text-12 text-cf-light-3',
              'transition duration-100 ease-out hover:bg-cf-gray-5 hover:text-cf-light-4',
              open && 'border-cf-light-1 bg-cf-gray-5 text-cf-light-4',
            )}
            data-testid="slippage-popup-toggle"
          >
            {effectiveSlippageTolerancePercent < SLIPPAGE_WARNING_LOW ||
            effectiveSlippageTolerancePercent > SLIPPAGE_WARNING_HIGH ? (
              <WarningTriangleIcon className="text-cf-orange-1" />
            ) : (
              <ShieldTickIcon />
            )}

            {slippageTolerancePercent === 'auto'
              ? `Auto ${effectiveSlippageTolerancePercent}%`
              : `${effectiveSlippageTolerancePercent}%`}
          </Popover.Button>
          <Transition
            className="relative z-10 transition-[opacity,scale]"
            enter="ease-out duration-100 scale-100"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <Popover.Panel className="flex w-[300px] flex-col space-y-3 rounded-md border border-cf-gray-5 bg-cf-gray-4 p-3">
              <SlippageProtectionSection open={open} />
            </Popover.Panel>
          </Transition>
        </>
      );
    }}
  </Popover>
);
