import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import useTracking from '@/shared/hooks/useTracking';
import RouteCard, { RouteCardLoading, RouteCardNotFound } from './RouteCard';
import { SlippageTolerancePopup } from './SlippageTolerancePopup';
import useBoost from '../../../hooks/useBoost';
import { useCountdown } from '../../../hooks/useCountdown';
import useRoutes from '../../../hooks/useRoutes';
import useSwapRequestStore from '../../../hooks/useSwapRequestStore';
import { type RouteResponse } from '../../../integrations';
import { SwapEvents, type SwapTrackEvents } from '../../../types/track';
import BoostToggle from '../SwapCard/BoostToggle';

export const RouteList = () => {
  const track = useTracking<SwapTrackEvents>();
  const { routes, isLoading: routesLoading, refresh } = useRoutes();
  const secondsUntilNextRefresh = useCountdown(30, !routesLoading, refresh);
  const selectedRoute = useSwapRequestStore((state) => state.selectedRoute);
  const setSelectedRoute = useSwapRequestStore((state) => state.setSelectedRoute);
  const { boostActive } = useBoost();

  useEffect(() => {
    setSelectedRoute(undefined);
  }, []);

  const memo = useMemo(() => {
    if (!routes.length) return undefined;

    const { best, worst } = routes.reduce(
      (acc, curr) => ({
        best: acc.best.destAmount > curr.destAmount ? acc.best : curr,
        worst: acc.worst.destAmount < curr.destAmount ? acc.worst : curr,
      }),
      { best: routes[0], worst: routes[0] },
    );

    const differenceFromWorstRoute = best.destAmount.sub(worst.destAmount).toPreciseFixedDisplay();

    const chainflipRoute = routes.find((route) => route.integration === 'chainflip');
    const recommendedRoute = chainflipRoute ?? best;

    return {
      bestRoute: best,
      differenceFromWorstRoute,
      recommendedRoute,
    };
  }, [routes, selectedRoute]);

  useEffect(() => {
    const updatedSelectedRoute =
      selectedRoute && routes.find((route) => route.id === selectedRoute.id);

    setSelectedRoute(updatedSelectedRoute ?? memo?.recommendedRoute);
  }, [routes, memo, selectedRoute]);

  const onRouteSelected = useCallback(
    (route: RouteResponse) => {
      if (selectedRoute?.id !== route.id) {
        track(SwapEvents.SelectRoute, {
          props: {
            assetFrom: `${route.srcToken.chain.name}.${route.srcToken.symbol}`,
            assetFromAmount: route.srcAmount.toFixed(),
            assetTo: `${route.destToken.chain.name}.${route.destToken.symbol}`,
            quotedAmount: route.destAmount.toFixed(),
            estRate: route.destAmount.ratio(route.srcAmount).toFixed(),
            isRecommended: route.id === memo?.recommendedRoute?.id,
            isNative: route.integration === 'chainflip',
            integration: route.integration,
            isBoostable: boostActive,
            dcaParams:
              route.integration === 'chainflip' ? route.integrationData.dcaParams : undefined,
          },
        });
      }
      setSelectedRoute(route);
    },
    [selectedRoute],
  );

  return (
    <div className="flex w-full flex-col space-y-4 rounded-md border border-cf-gray-4 bg-cf-gray-3 p-4 shadow-grayPop1 md:w-[430px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-1">
          <span className="font-aeonikMedium text-14 text-cf-light-4">Route</span>
          <span className="font-aeonikMedium text-12 text-cf-light-2">
            ({Number(secondsUntilNextRefresh)}s)
          </span>
        </div>
        <div className="flex items-center gap-x-2">
          <BoostToggle />
          <SlippageTolerancePopup />
        </div>
      </div>

      {routesLoading && routes.length === 0 && <RouteCardLoading />}
      {!routesLoading && routes.length === 0 && <RouteCardNotFound />}

      <motion.div
        className={classNames('space-y-5 font-aeonikMedium text-12 text-cf-light-2')}
        animate="open"
        variants={{
          open: { height: 'auto', opacity: 1 },
          closed: { height: 0, opacity: 1 },
        }}
        transition={{ duration: 0.15 }}
        exit={{ opacity: 0, height: 0 }}
      >
        <div className="h-full w-full space-y-4">
          {routes.map((route) => (
            <RouteCard
              key={route.id}
              route={route}
              isSelected={selectedRoute?.id === route.id}
              onRouteSelected={onRouteSelected}
              differenceFromWorstRoute={
                memo?.bestRoute.id === route.id ? memo.differenceFromWorstRoute : undefined
              }
              secondsUntilNextRefresh={secondsUntilNextRefresh}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default RouteList;
