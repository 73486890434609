import React from 'react';
import classNames from 'classnames';
import { Link, Tooltip } from '@/shared/components';
import { ClockIcon, TimeBoost } from '@/shared/icons/small';
import { formatToApproxTime } from '@/shared/utils';
import type { RouteResponse } from '../../../integrations';

export const DurationBadge = ({ route }: { route: RouteResponse }) => {
  const isBoosted = route.integration === 'chainflip' && route.integrationData.isBoosted;
  const formattedDuration =
    route.durationSeconds > 0 ? formatToApproxTime(route.durationSeconds) : undefined;
  const formattedDefaultDuration =
    route.integration === 'chainflip' && route.integrationData.defaultDurationSeconds > 0
      ? formatToApproxTime(route.integrationData.defaultDurationSeconds)
      : undefined;

  return (
    <div className={classNames('flex items-center font-aeonikMedium text-13 text-cf-light-3')}>
      <Tooltip
        tooltipClassName="w-[306px]"
        content={
          <div>
            Estimated time to receive, process, and send your funds to the destination address.{' '}
            <Link
              href="https://docs.chainflip.io/swapping/swapping-basics#estimating-time-for-completion"
              target="_blank"
              color="green"
            >
              Learn more
            </Link>
          </div>
        }
      >
        <div className="flex items-center gap-x-0.5 p-1">
          {isBoosted ? <TimeBoost className="text-cf-pink-1" /> : <ClockIcon />}
          <span>{formattedDuration}</span>
          {isBoosted && formattedDefaultDuration && (
            <span className="pl-0.5 text-12 text-cf-light-2 line-through">
              {formattedDefaultDuration}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
