import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import MotionNumber from 'motion-number';
import type { Token } from '@/shared/assets/tokens';
import { ChainTokenLogo, SkeletonLine } from '@/shared/components';
import { ArrowIcon } from '@/shared/icons/large';
import { CheckCircleIcon } from '@/shared/icons/small';
import { chainflipAssetMap, TokenAmount } from '@/shared/utils';
import { GridFrame } from './GridFrame';
import usePriceDelta from '../../../hooks/usePriceDelta';
import { integrationManager } from '../../../integrations';

const TokenCard = ({ token }: { token: Token }) => (
  <div className="rounded-md border border-[#2D2D2D] p-2 [background:linear-gradient(180deg,#252525_0%,#1D1D1D_100%)]">
    <ChainTokenLogo token={token} size="large" displayChainLogo={false} />
  </div>
);

export const BraggingCard = () => {
  const srcToken = chainflipAssetMap.Btc;
  const destToken = chainflipAssetMap.Eth;
  const srcAmount = TokenAmount.fromWholeUnits(1, srcToken.decimals);

  const { data: quote } = useQuery({
    queryKey: ['bragging-quote'],
    queryFn: async () => {
      const chainflipIntegration = integrationManager.getIntegration('chainflip');
      const routes = await chainflipIntegration.getRoutes({
        srcChainId: srcToken.chain.id,
        srcTokenAddress: srcToken.address,
        destChainId: destToken.chain.id,
        destTokenAddress: destToken.address,
        amount: srcAmount.toBigInt(),
      });

      return routes.toSorted((r1, r2) => r2.destAmount.toNumber() - r1.destAmount.toNumber()).at(0);
    },
    refetchInterval: 30000,
  });

  const { delta, color: deltaColor } = usePriceDelta({
    srcAmount: quote?.srcAmount,
    destAmount: quote?.destAmount,
    srcToken: quote?.srcToken,
    destToken: quote?.destToken,
  });

  const tokens = [
    chainflipAssetMap.Btc,
    chainflipAssetMap.Eth,
    chainflipAssetMap.Sol,
    chainflipAssetMap.Flip,
    chainflipAssetMap.Dot,
    chainflipAssetMap.Usdc,
    chainflipAssetMap.Usdt,
  ];

  return (
    <div className="relative flex w-full flex-col gap-6 overflow-hidden rounded-md border border-cf-gray-4 bg-cf-gray-3 p-4 shadow-grayPop1 md:w-[430px]">
      <GridFrame className="pointer-events-none absolute left-0 top-0" />
      <motion.div
        className="flex flex-row gap-8 py-4"
        animate={{
          x: [-tokens.length * 82, 0], // 50px token card width + 32px gap
          transition: {
            ease: 'linear',
            duration: 20,
            repeat: Infinity,
          },
        }}
      >
        {tokens.map((token) => (
          <TokenCard key={token.chainflipId} token={token} />
        ))}
        {/* render tokens two times for infinite slider */}
        {tokens.map((token) => (
          <TokenCard key={token.chainflipId} token={token} />
        ))}
      </motion.div>
      <div className="absolute inset-x-0 top-0 h-[115px] [background:linear-gradient(90deg,#161616_0%,rgba(22,22,22,0.00)_50%,#161616_100%)]" />
      <div className="flex flex-col items-center font-aeonikMedium text-cf-light-2">
        <div className="text-20 leading-6">
          <span className="text-cf-white">Excellent</span> pricing.
        </div>
        <div className="text-20 leading-6">
          Emphasis on <span className="text-cf-white">excellent</span>.
        </div>
        <div className="flex flex-row items-center gap-1 pt-4">
          <span className="text-30 text-cf-white">{srcAmount.toPreciseFormattedString(2)}</span>
          <span className="text-14">{srcToken.symbol}</span>
          <ArrowIcon direction="right" />
          {quote ? (
            <span className="-mt-1.5 text-30 text-cf-white">
              <MotionNumber
                value={quote.destAmount.toFixedDisplay()}
                format={{ minimumFractionDigits: 2 }}
              />
            </span>
          ) : (
            <SkeletonLine width={40} />
          )}
          <span className="text-14">{destToken.symbol}</span>
        </div>
        <div className="text-12">
          {delta ? (
            <span className={deltaColor}>({delta.toFixed(1)}%)</span>
          ) : (
            <SkeletonLine width={30} inline />
          )}{' '}
          Est. price delta
        </div>
      </div>
      <div className="flex flex-row justify-between gap-2 px-2 pt-8 font-aeonikMedium text-12 text-cf-light-2">
        <div className="flex flex-row items-center gap-x-1">
          <CheckCircleIcon />
          <div>Native assets</div>
        </div>
        <div className="flex flex-row items-center gap-x-1">
          <CheckCircleIcon />
          <div>Better pricing</div>
        </div>
        <div className="flex flex-row items-center gap-x-1">
          <CheckCircleIcon />
          <div>No wallet needed</div>
        </div>
      </div>
    </div>
  );
};
